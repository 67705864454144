<template>
  <div>
    <a-table :data-source="list"
             :rowKey="record => record.productId"
             :columns="columns"
             :pagination="false"
             bordered>
      <div slot="action" slot-scope="scope">
      <div>{{scope.specification}}</div>
      </div>
      <div slot="action1" slot-scope="scope">
         <a-input-number v-if="scope.awardType === 1" v-model="scope.award"/> 元
         <a-input-number v-if="scope.awardType === 2" v-model="scope.awardRatio"/>
      </div>
    </a-table>
    <div slot="footer" style=" display:flex;justify-content:center;padding-top:20px;">
      <a-button key="back" @click="handleCancel">
        取消
      </a-button>
      &emsp;
      <a-button key="submit" type="primary" :loading="loading" @click="handleOk">
        确认
      </a-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      productIds: [],
      form: {
        list: []
      },
      visible: false,
      disabledStatus: false, // 是否禁用
      isShow: false, // 是否显示弹窗
      isDetermineLoading: false, // 是否显示确定loading
      app: {}, // 应用信息
      columns: [
        { title: '商品/规格', width: 400, key: 'nameGoyGoods', scopedSlots: { customRender: 'action' } },
        { title: '商品奖励池', key: 'saleAmount', scopedSlots: { customRender: 'action1' } }
      ],
      list: []
    }
  },
  watch: {
    isShow (val) {
      if (val) {
        this.list = this.app.productList
      }
    }
  },
  computed: {
  },
  methods: {
    async init (app) {
      let productList = app.productList
      let productIds2 = []
      productList.forEach(product => {
        productIds2.push(product.id)
      })
      this.productIds = productIds2
      let res = await this.$store.dispatch('http', {
        api: 'getSaleProductConfig',
        params: {
          productIds: this.productIds.join(',')
        },
        isJson: false
      })
      console.log(res, '货品奖励池信息')
      if (!res.length) {
        let list2 = []
        for (let i = 0; i < productList.length; i++) {
          let obj = {}
          obj.specification = productList[i].specification
          obj.productId = productList[i].id
          obj.awardType = 1
          obj.award = 0
          obj.awardRatio = 0
          list2.push(obj)
        }
        this.list = list2
      } else {
        this.list = res
        this.list.forEach(item => {
          const ele = productList.filter(p => p.id === item.productId)[0]
          item.specification = ele.specification
        })
      }
    },
    getParams () {
      let arr = []
      for (const { productId, award, awardRatio, awardType } of this.list) {
        arr.push({
          productId: productId,
          awardType: awardType,
          award: award,
          awardRatio: awardRatio
        })
      }
      return arr
    },
    async handleOk (e) {
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 2000)
      const params = this.getParams()
      const res = await this.$store.dispatch('http', {
        api: 'editSaleProductConfig',
        params: params
      })
      if (res) {
        this.loading = false
        this.$emit('ok')
        setTimeout(() => {
          this.visible = false
        })
      }
    },
    handleCancel (e) {
      this.$emit('ok')
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
