<template>
    <div>
        <a-form-model ref="form" :model="form" :rules="rules" class="al-form" :label-col="{ span: 6 }"
            :wrapper-col="{ span: 16 }">
            <a-form-model-item label="排序:" prop="sort" style="margin-bottom:0">
                <a-input-number :min="1" v-model="form.sort" placeholder="请输入排序号" style="width: 300px;"/>
            </a-form-model-item>
            <a-form-item :wrapper-col="{ span: 14, offset: 6 }">
                <a-button type="primary" :loading="isDetermineLoading"  @click.prevent="onSubmit">提交</a-button>
                <a-button style="margin-left: 10px" @click="resetFields">取消</a-button>
            </a-form-item>
        </a-form-model>
    </div>
</template>

<script>
export default {
  data () {
    return {
      isDetermineLoading: false,
      form: {
        goodsId: undefined,
        sort: undefined
      },
      rules: {
        sort: [{ required: true, message: '请输入排序号', trigger: 'change' }]
      }
    }
  },
  watch: {

  },
  computed: {
  },
  methods: {
    init ({ goodId, sort }) {
      this.$refs.form.clearValidate(['sort'])

      this.form.goodsId = goodId
      this.form.sort = sort
    },
    resetFields () {
      this.$emit('ok')
    },
    async onSubmit () {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.isDetermineLoading = true
          let res = await this.$store.dispatch('http', {
            api: 'editGoodsSort',
            query: {
              goodsId: this.form.goodsId,
              sort: this.form.sort
            },
            complete: () => {
              this.isDetermineLoading = false
            },
            error: () => {
              this.$store.dispatch('showToast', { type: 'error', message: '请求出错了！' })
            },
            success: () => {
              this.$store.dispatch('showToast', { type: 'success', message: '操作成功' })
              this.$emit('ok')
            }
          })
          console.log(res)
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
    width: auto;
}
</style>
