<template>
  <div v-if="showPage">
    <a-table :data-source="list"
             :rowKey="record => record.productId"
             :columns="computedColumns"
             :pagination="false"
             :scroll="{ x: 600, y: 580 }">
      <div slot="action1"
           slot-scope="scope">
        <a  @click="zoom(scope.imgUrl)">
          <a-avatar shape="square"
                    style="width:80px;height:80px;"
                    :src="scope.imgUrl" />
        </a>
      </div>
      <div slot="action2"
           slot-scope="scope">
        <div>{{scope.id}}</div>
        <div>{{scope.name}}</div>

      </div>
      <div slot="beforePrice"
           slot-scope="scope">
           <div v-if="!scope.primePrice">
              <!-- <div v-if="valHasPresell">
                订金{{scope.beforePrice.presellPrice}}&emsp;尾款{{scope.beforePrice.presellEndPrice}}
              </div> -->
              <div>
                销售价&emsp;{{scope.beforePrice.price}}&emsp;元
              </div>
           </div>
           <div v-else>
            <template v-for="(ele,index) in scope.beforePrimePrice">
              <div v-if="valHasPresell" :key="index">{{ele.primeLevel}}&emsp;订金{{ele.presellPrice}}&emsp;尾款{{ele.presellEndPrice}}</div>
              <div v-else :key="index">{{ele.primeLevel}}&emsp;价格{{ele.primePrice}}</div>
            </template>
           </div>
      </div>
      <div slot="afterPrice"
           slot-scope="scope">
          <!-- <div v-if="valHasPresell">
            订金 <a-input-number v-model="scope.presellPrice"/>&emsp;
            尾款 <a-input-number v-model="scope.endPrice" />
          </div> -->
          <div>
            销售价 <a-input-number v-model="scope.price"/> 元&emsp;&emsp;
            市场价 <a-input-number v-model="scope.marketPrice"/> 元&emsp;&emsp;
            成本价 <a-input-number v-model="scope.costPrice"/> 元
          </div>
      </div>
      <template v-for="(item,index) in columnsVip" :slot="item.title"
           slot-scope="scope">
           <!-- {{index-2}}--
           {{scope.primePrice[index-2]}} -->
          <div :key="index" v-if="valHasPresell">
            订金 <a-input-number v-model="scope.primePrice[index-2].presellPrice"/>&emsp;
            尾款 <a-input-number v-model="scope.primePrice[index-2].presellEndPrice" />
          </div>
          <div :key="index" v-else>
            价格 <a-input-number v-model="scope.primePrice[index-2].primePrice"/>
          </div>
      </template>
      <div slot="beforeStock"
           slot-scope="scope">
       {{scope.stock}}
      </div>
      <div slot="afterStock"
           slot-scope="scope">
          <a-input-number v-model="scope.afterStock" :min="1" :step="1" :max="100000" />
      </div>
      <div slot="action3"
           slot-scope="scope">
        <div>{{scope.specification}}</div>
      </div>
      <div slot="action4"
           slot-scope="scope">
        <div>￥{{scope.price}}</div>
      </div>

      <div slot="action5"
           slot-scope="scope">
        <div>{{scope.stock}}</div>
      </div>
      <div slot="action6"
           slot-scope="scope">
        <div>{{scope.outId}}</div>
      </div>
    </a-table>
    <a-modal
      :footer="null"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <img style="width:100%" :src="doctorImag"/>
    </a-modal>
    <div slot="footer" style=" display:flex;justify-content:center;padding-top:20px;">
      <a-button key="back" @click="handleCancel">
        取消
      </a-button>
      &emsp;
      <a-button key="submit" type="primary" :loading="loading" @click="handleOk">
        确认
      </a-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showPage: false,
      valHasPresell: false, // 商品有没有预售信息  有没有开启预售
      apiStr: '', // price   stock   erpset
      loading: false,
      editorAdd: '',

      id: '',
      form: {
        list: []
      },
      visible: false,
      doctorImag: '',
      disabledStatus: false, // 是否禁用
      isShow: false, // 是否显示弹窗
      isDetermineLoading: false, // 是否显示确定loading
      app: {}, // 应用信息
      columns: [
        { title: '商品/规格', width: 200, key: 'nameGoyGoods', scopedSlots: { customRender: 'action3' } },
        { title: '当前售价', width: 100, key: 'beforePrice', scopedSlots: { customRender: 'beforePrice' } },
        { title: '调整后售价', width: 300, key: 'afterPrice', scopedSlots: { customRender: 'afterPrice' } }
      ],
      columnsVip: [
      ],
      columnsStock: [
        { title: '商品/规格', width: 80, key: 'nameGoyGoods', scopedSlots: { customRender: 'action3' } },
        { title: '当前库存', width: 80, key: 'beforeStock', scopedSlots: { customRender: 'beforeStock' } },
        { title: '调整库存', width: 80, key: 'afterStock', scopedSlots: { customRender: 'afterStock' } }
      ],
      list: []
    }
  },
  watch: {
    isShow (val) {
      if (val) {
        console.log('传过来的数据', this.app)
        this.list = this.app.productList
      }
    }
  },
  computed: {
    computedColumns () {
      if (this.apiStr === 'price') return [...this.columnsVip]
      if (this.apiStr === 'stock') return [...this.columnsStock]
      return [...this.columns]
    }
  },
  methods: {
    onChangeStock (e, app) {
      console.log(e, app, 'e app')
      this.form.list.forEach((item) => {
        if (item.idGoProduct === app.id) {
          item.stock = e
        }
      })
    },
    async hasPresellVal (businessProperty) {
      for (const { propertyCode } of businessProperty) {
        if (propertyCode === 'presell') return true
      }
      return false
    },
    async init (item) {
      this.apiStr = item.editorAdd
      this.editorAdd = item.editorAdd
      this.id = item.id
      let res = await this.$store.dispatch('http', {
        api: 'getGoodInfoDetail',
        query: { id: this.id }
      })
      console.log(res, 'ressssfffff')

      // 先判断是不是会员  有没有  vip属性
      const hasPresell = await this.hasPresellVal(res.businessProperty)
      this.valHasPresell = hasPresell
      let goProductList = res.productList

      // 单规格 多规格 设置 规格栏的显示隐藏
      // if (goProductList[0].specification === '') {
      //   this.columns[0].title = this.columnsStock[0].title = ''
      //   this.columns[0].width = this.columnsStock[0].width = 0
      // } else {
      //   this.columns[0].title = this.columnsStock[0].title = '规格'
      //   this.columns[0].width = this.columnsStock[0].width = 300
      // }

      const hasVips = await this.getVipColumns(goProductList[0])
      console.log(hasVips, 'hasVips 的长度')
      if (hasVips) {
        goProductList.forEach(element => {
          if (element.primePrice) {
            element.primePrice.forEach(item => {
              console.log(item, 'eeeeee')
              item.presellEndPrice = parseFloat(item.primePrice - item.presellPrice).toFixed(2)
              item.presellEndPrice = parseFloat(item.presellEndPrice)
            })
            element.beforePrimePrice = this.$utils.clone(element.primePrice)
          }
        })
        console.log(goProductList, 'goProductList')
      }

      // 单独设置 after stock
      goProductList.forEach(element => {
        // 基础价格外层价格在这里设置
        element.endPrice = element.endPriceConst = parseFloat(element.price - element.presellPrice).toFixed(2)
        element.endPrice = parseFloat(element.endPrice)
        element.beforePrice = {}
        element.beforePrice.price = element.price
        element.beforePrice.presellPrice = element.presellPrice
        let presellEndPrice = parseFloat(element.price - element.presellPrice).toFixed(2)
        element.beforePrice.presellEndPrice = parseFloat(presellEndPrice)

        element.afterStock = element.stock
      })

      this.list = goProductList
      this.showPage = true
    },
    async getVipColumns (listOne) {
      let { primePrice } = { ...listOne }
      let obj = this.$utils.clone(this.columns)
      console.log(primePrice, 'primePrice')
      if (!primePrice) { this.columnsVip = obj; return 0 }

      obj.pop() // 删除最后一个 基础价格  不用显示
      for (const { primeLevel } of primePrice) {
        obj.push({ title: primeLevel, width: 300, key: primeLevel, scopedSlots: { customRender: primeLevel } })
      }
      this.columnsVip = obj
      console.log(primePrice, 'premilength')
      return primePrice.length
    },
    getParams (apii) {
      let arr = []
      console.log(apii)
      if (apii === 'editGoodsPrice') {
        for (const { price, marketPrice, costPrice, productId } of this.list) {
          arr.push({
            idGoProduct: productId,
            price: price,
            marketPrice: marketPrice,
            costPrice: costPrice
          })
        }
        return arr
      } else {
        for (const { productId, afterStock } of this.list) {
          arr.push({
            idGoProduct: productId,
            stock: afterStock
          })
        }
        return arr
      }
    },
    async handleOk (e) {
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 2000)
      let apii = this.apiStr === 'price' ? 'editGoodsPrice' : 'editGoodsStack'
      const params = this.getParams(apii)
      // if (params) {
      //   console.log(params, '最后parmas')
      //   return
      // }
      const res = await this.$store.dispatch('http', {
        api: apii,
        query: {
          id: this.id
        },
        params: params
      })
      if (res) {
        this.loading = false
        this.$emit('ok')
        setTimeout(() => {
          this.visible = false
        })
      }
    },
    handleCancel (e) {
      this.$emit('ok')
    },
    zoom (scope) {
      this.visible = true
      this.doctorImag = scope
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
