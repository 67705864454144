<!--
 * @Descripttion:弹窗口 查看 商品的规格详情
 * @version:
 * @Author: 高涛
 * @Date: 2021-10-25 14:48:34
 * @LastEditors: 高涛
 * @LastEditTime: 2021-11-16 12:20:56
-->
<template>
  <a-modal width="960px" :footer="null"  v-model="isShow" title="查看规格详情">
    <a-table :data-source="list"
             :rowKey="record => record.id"
             :columns="columns"
             :pagination="false"
             :scroll="{ x: 600, y: 580 }">
      <div slot="action1"
           slot-scope="scope">
        <a  @click="zoom(scope.imgUrl)">
          <a-avatar shape="square"
                    style="width:80px;height:80px;"
                    :src="scope.imgUrl" />
        </a>
      </div>
      <div slot="action2"
           slot-scope="scope">
        <div>{{scope.id}}</div>
        <div>{{scope.name}}</div>

      </div>
      <div slot="action3"
           slot-scope="text,record">
        <div>{{record.specification}}</div>
      </div>
      <div slot="action4"
           slot-scope="scope">
        <div>￥{{scope.price}}</div>
      </div>

      <div slot="action5"
           slot-scope="scope">
        <div>{{scope.stock}}</div>
      </div>
      <div slot="productBarcode"
           slot-scope="scope">
        <div>{{scope.productBarcode}}</div>
      </div>
    </a-table>
    <a-modal
      :footer="null"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <img style="width:100%" :src="doctorImag"/>
    </a-modal>
    <div slot="footer"></div>
  </a-modal>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      doctorImag: '',
      disabledStatus: false, // 是否禁用
      isShow: false, // 是否显示弹窗
      isDetermineLoading: false, // 是否显示确定loading
      app: {}, // 应用信息
      columns: [
        { title: '规格', width: 80, key: 'product', scopedSlots: { customRender: 'action3' } },
        { title: '价格(元)', width: 40, key: 'price', scopedSlots: { customRender: 'action4' } },
        { title: '库存', width: 40, key: 'stock', scopedSlots: { customRender: 'action5' } },
        { title: '商家编码', width: 80, key: 'productBarcode', scopedSlots: { customRender: 'productBarcode' } }
      ],
      list: []
    }
  },
  watch: {
    isShow (val) {
      if (val) {
        console.log('传过来的数据', this.app)
        this.list = this.app.productList
      }
    }
  },
  methods: {
    async getInfo (app) {
      const res = await this.$store.dispatch('http', {
        api: 'getGoodInfo',
        query: { id: app.id }
      })
      this.list = res.goProductList
    },
    handleOk (e) {
      setTimeout(() => {
        this.visible = false
      })
    },
    handleCancel (e) {
      console.log('Clicked cancel button')
      this.visible = false
    },
    zoom (scope) {
      this.visible = true
      this.doctorImag = scope
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
