<template>
  <div :class="$style.container">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
        <a-button class="add-bt" type="primary" @click="addGoods">新增</a-button>
    </Breadcrumb>
    <a-form ref="form" class="al-form" layout="inline" :model="form" :label-col="{ style: 'width: 80px' }" :wrapper-col="{ style: 'width: 300px' }">
      <a-form-item label="商品名称:">
        <a-input v-model="form.name" placeholder="请输入" />
      </a-form-item>

      <a-form-item>
        <a-button type="primary" :loading="isQueryLoading" @click="queryList">查询</a-button>
        <a-button @click="resetForm('form')" style="margin-left:20px;">重置</a-button>
        <a-button style="margin-left:20px;" @click="exportGoods" type="primary">商品导出</a-button>
      </a-form-item>
    </a-form>

    <a-table :data-source="list"
             bordered
             :rowKey="record => record.id"
             @change="currentChange"
             :columns="columns"
             :pagination="pagination">
      <div slot="action1"
           slot-scope="scope">
        <a  @click="zoom(scope.imgUrl)">
          <a-avatar shape="square"
                    style="width:80px;height:80px;"
                    :src="scope.imgUrl" />
        </a>
      </div>
      <div slot="action2"
           slot-scope="scope">
        <div>{{scope.id}}</div>
        <div>{{scope.name}}</div>

      </div>
      <div slot="action3"
           slot-scope="app">
        <a-button type="primary"
                  @click="$refs.loadProductsDialog.app = $utils.clone(app);$refs.loadProductsDialog.isShow = true">
          查看规格
        </a-button>
      </div>
      <div slot="action4"
           slot-scope="scope">
        ￥{{scope.minPrice}} - ￥{{scope.maxPrice}}
        <!-- <div v-if="scope.productList.length == 1">￥{{scope.minPrice}}</div> -->
      </div>

      <div slot="action5"
           slot-scope="scope">
        <div>{{scope.totalStock}}</div>
      </div>
      <div slot="action6"
           slot-scope="scope">
        <div>{{scope.totalSales}}</div>
      </div>
      <div slot="action7"
           slot-scope="scope">
        <div>{{scope.gmtCreate}}</div>
      </div>
      <span slot="action"
            slot-scope="app">
        <div style="align:center;">
          <a @click="eidt(app)" ><a-spin size="small" v-if="app.isLoading" />编辑</a>
          <a-divider type="vertical" />
          <a @click="(()=>{showModal({ editorAdd:'stock', id:app.id });modalTitle='调整库存'})">库存</a>
          <a-divider type="vertical" />
          <a @click="(()=>{showModal({ editorAdd:'price', id:app.id });modalTitle='调整价格'})">价格</a>
          <a-divider type="vertical" />
          <a @click="del(app)"><a-spin size="small" v-if="app.isLoading" />删除</a>
        </div>
        <div style="align:center;">
          <a v-if="app.onsell === 0" @click="online(app)"><a-spin size="small" v-if="app.isLoading" />上架</a>
           <a v-if="app.onsell === 1" @click="offline(app)"><a-spin size="small" v-if="app.isLoading" />下架</a>
          <a-divider type="vertical" />
          <a @click="(()=>{showModalSale(app);modalTitle='分销设置'})"><a-spin size="small" v-if="app.isLoading" />分销设置</a>
          <a-divider type="vertical" />
          <a @click="(()=>{showSortModal(app);modalTitle='排序'})"><a-spin size="small" v-if="app.isLoading" />排序</a>
        </div>
        <div style="align:center;">
          <a @click="(()=>{setTop(app)})"><a-spin size="small" v-if="app.isLoading" />置顶</a>
        </div>
      </span>
    </a-table>
    <load-products ref="loadProductsDialog"></load-products>
    <a-modal
      :footer="null"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <img style="width:100%" :src="doctorImag"/>
    </a-modal>
    <!-- 弹窗口 -->
    <a-modal :width="1300" v-model="visibleStack" :title="modalTitle" @ok="handleOk" :footer="null">
      <StackAndPrice ref="StackAndPrice"  @ok="handleOk"></StackAndPrice>
    </a-modal>
    <a-modal :width="800" v-model="visibleSale" :title="modalTitle" @ok="handleOk" :footer="null">
      <EditSale ref="editSale"  @ok="handleOk"></EditSale>
    </a-modal>

    <a-modal :width="800" v-model="sortVisible" :title="modalTitle" @ok="handleOk" :footer="null">
      <EditSort ref="editSort"  @ok="handleOk"></EditSort>
    </a-modal>
  </div>
</template>

<script>
import { getQueryString } from '@/utils'
import store from '@/store'
import Breadcrumb from '@/components/breadcrumb.vue'
import loadProducts from './components/specDetail.vue'
import StackAndPrice from './components/stackAndPrice.vue'
import EditSale from './components/editSale.vue'
import EditSort from './components/editSort.vue'

export default {
  props: ['onsell'],
  components: { Breadcrumb, loadProducts, StackAndPrice, EditSale, EditSort },
  data () {
    return {
      breadList: [
        {
          name: '商品管理',
          url: ''
        }
      ],
      modalTitle: '',
      visibleSale: false,
      sortVisible: false,
      visibleStack: false,
      visible: false,
      doctorImag: '',
      isQueryLoading: false,
      form: {
        name: ''
      },
      columns: [
        { title: '图片', width: 120, key: 'imgUrl', scopedSlots: { customRender: 'action1' } },
        { title: '商品名称', key: 'name', scopedSlots: { customRender: 'action2' } },
        { title: '规格/规格值', width: 120, key: 'nameGoyGoods', scopedSlots: { customRender: 'action3' } },
        { title: '价格(元)', key: 'price', scopedSlots: { customRender: 'action4' } },
        { title: '总库存', width: 100, key: 'stock', scopedSlots: { customRender: 'action5' } },
        { title: '销量', width: 100, key: 'totalSales', scopedSlots: { customRender: 'action6' } },
        { title: '发布时间', width: 180, key: 'gmtAudit', scopedSlots: { customRender: 'action7' } },
        { title: '排序', width: 100, key: 'sort', dataIndex: 'sort' },
        { title: '操作', key: 'operation', fixed: 'right', width: 220, scopedSlots: { customRender: 'action' } }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共${total}条记录,第${this.pagination.current}页`
      },
      showList: [],
      total: 0,
      list: [],
      titleList: []
    }
  },
  created () {
    this.reloadList()
  },
  mounted () {
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        console.log('我回来了')
        this.loadList()
      }
    })
  },
  destroyed () {
  },
  computed: {
  },
  methods: {
    addGoods () {
      const { href } = this.$router.resolve({
        path: '/goods/addOrEdit'
      })
      window.open(href, '_blank')
    },
    // 弹出窗口
    showModal ({ editorAdd, id, parentId }) {
      this.visibleStack = true
      setTimeout(() => {
        this.$refs.StackAndPrice.init({ editorAdd, id, parentId })
      }, 20)
    },
    showSortModal (app) {
      this.sortVisible = true
      setTimeout(() => {
        this.$refs.editSort.init({ goodId: app.id, sort: app.sort })
      }, 20)
    },
    async setTop (app) {
      let res = await this.$store.dispatch('http', {
        api: 'editGoodsSort',
        query: {
          goodsId: app.id,
          sort: 0
        },
        complete: () => {
        },
        error: () => {
          this.$store.dispatch('showToast', { type: 'error', message: '请求出错了！' })
        },
        success: () => {
          this.$store.dispatch('showToast', { type: 'success', message: '操作成功' })
          this.loadList()
        }
      })
      console.log(res)
    },
    showModalSale (app) {
      this.visibleSale = true
      setTimeout(() => {
        this.$refs.editSale.init(app)
      }, 20)
    },
    eidt (app) {
      // console.info(app.id)
      const { href } = this.$router.resolve({
        path: '/goods/addOrEdit',
        query: {
          id: app.id
        }
      })
      window.open(href, '_blank')
    },
    handleOk (e) {
      setTimeout(() => {
        this.visible = false
        this.visibleStack = false
        this.visibleSale = false
        this.sortVisible = false
      })
      this.queryList()
    },
    handleCancel (e) {
      console.log('Clicked cancel button')
      this.visible = false
    },
    zoom (scope) {
      this.visible = true
      this.doctorImag = scope
    },
    // 重置表单
    resetForm () {
      this.form = {
        name: ''
      }
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => { }) {
      this.pagination.current = 1
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => { }) {
      this.isTableLoading = true
      const tenantId = this.$store.state.user.currentTenantId
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        tenantId: tenantId,
        onsell: this.onsell
      }, this.form)
      const { total, records } = await this.$store.dispatch('http', {
        api: 'goGoodsPageManage',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.pagination.total = total
      this.list = records
    },
    // 分页、排序、筛选变化时触发
    currentChange (val) {
      console.log('分页、排序、筛选变化时触发', val)
      this.pagination.pageSize = val.pageSize
      this.pagination.current = val.current
      this.loadList()
    },
    // 上架
    online (app) {
      this.$store.dispatch('showConfirm', {
        message: '您确定要上架吗？',
        yes: async _ => {
          app.isLoading = true
          await this.$store.dispatch('http', {
            api: 'goodsOnSell',
            query: {
              id: app.id,
              online: 1
            },
            success: () => {
              app.isLoading = false
              this.$store.dispatch('showToast', { message: '上架成功~' })
              this.reloadList()
            }
          })
        }
      })
    },
    // 下架
    offline (app) {
      this.$store.dispatch('showConfirm', {
        message: '您确定要下架吗？',
        yes: async _ => {
          app.isLoading = true
          await this.$store.dispatch('http', {
            api: 'goodsOnSell',
            query: {
              id: app.id,
              online: 0
            },
            success: () => {
              app.isLoading = false
              this.$store.dispatch('showToast', { message: '下架成功~' })
              this.reloadList()
            }
          })
        }
      })
    },
    // 删除
    del (app) {
      this.$store.dispatch('showConfirm', {
        message: '您确定要删除这条内容吗？',
        yes: async _ => {
          app.isLoading = true
          await this.$store.dispatch('http', {
            api: 'goodsDelete',
            query: {
              id: app.id
            },
            success: () => {
              app.isLoading = false
              this.$store.dispatch('showToast', { message: '删除成功~' })
              this.reloadList()
            }
          })
        }
      })
    },
    async exportGoods () {
      let that = this
      const tokenType = getQueryString('tokenType') || store.state.user.tokenType
      const tokenValue = getQueryString('tokenValue') || store.state.user.tokenValue
      const currentTenantId = getQueryString('currentTenantId') || store.state.user.currentTenantId
      const params = Object.assign({
        tenantId: currentTenantId,
        onsell: this.onsell
      }, this.form)
      axios({
        method: 'get',
        responseType: 'blob',
        url:
            process.env.VUE_APP_GATEWAY + '/goods/manage/goods/normal/export',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: tokenType + ' ' + tokenValue,
          tenantId: currentTenantId
        },
        params
      })
        .then(function (res) {
          if (res.data.type.includes('application/json')) {
            const reader = new FileReader()
            reader.onload = function (event) {
              console.log('event: ', event)
              const message = JSON.parse(event.target.result).msg
              that.$store.dispatch('showToast', {
                type: 'error',
                message: message
              })
            }
            reader.readAsText(res.data)
          } else {
            that.$store.dispatch('showToast', { message: '操作成功~' })
            const blob = new Blob([res.data], {
              type: 'application/vnd.ms-excel'
            })
            const fileName = res.headers['content-disposition']
              .split(';')[1]
              .split('filename=')[1]
            const eleLink = document.createElement('a')
            eleLink.download = decodeURIComponent(fileName)
            eleLink.style.display = 'none'
            eleLink.href = URL.createObjectURL(blob)
            // 触发点击
            document.body.appendChild(eleLink)
            eleLink.click()
            document.body.removeChild(eleLink)
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
